.audio-container {
  text-align: left;
}

.header-with-image {
  display: flex;
  align-items: center; /* Align vertically */
  justify-content: start; /* Space out the elements */
  margin-top: 20px;
}

.header-with-image h1 {
  font-size: 44px;
  padding-left: 10px;

}

.header-with-image img {
  height: 120px; /* Adjust size as needed */
  width: auto; /* Maintain aspect ratio */
  margin-left: 30px; /* Add space between h1 and the image */
}

.links-container {
  margin-top: 20px;
  padding-left: 20px;
  margin-bottom: 50px;
}

.links-container a {
  display: block; /* Makes each link a block for better clickability */
  margin: 30px 0; /* Adds some space between the links */
  text-decoration: none; /* Removes the underline from links */
  color: black; /* Change color as needed */
  font-size: 26px; /* Adjust the font size as needed */
}

.links-container a:hover {
  text-decoration: underline; /* Underline on hover for better UX */
}