.App-footer {
  display: flex;
  justify-content: left;
  width: 100%; /* Ensure full width */
  background-color: white;

}


.App-footer .logo {
    height: 160px; /* Adjust the height as needed */
    width: auto; /* This will maintain the aspect ratio of the logo */
    /* Add more styles as required */
}