.hero-section {
  background-image: url('../public/mtn_photo.jpg'); /* Path to your image */
  background-size: cover;
  background-position: center;
  position: relative;
  height: 60vh; /* Adjust the height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white; /* Text color */
  max-width:1350px;
}

.overlay-text {
  text-align: center;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  padding: 20px;
  border-radius: 10px;
}

.overlay-text h1 {
  font-size: 3.25rem; /* Larger font size for the heading */
  color: white; /* Optional: specify text color */
  margin-bottom: 0.5rem; /* Adjust bottom margin */
}

.overlay-text p {
  font-size: 1.5rem; /* Larger font size for the paragraph */
  color: white; /* Optional: specify text color */
  margin-bottom: 1rem; /* Adjust bottom margin */
  Font-weight: bold;
}

