/* Base styles for mobile and smaller screens */
.about-content {
  margin-top: 10px; /* Space below the header */
  text-align: left; /* Left-align text on small screens */
  padding: 0 10px; /* Padding on the sides */
}

.about-content h1 {
  font-size: 2.5rem; /* Responsive font size for mobile */
  margin-bottom: 20px; /* Space below the h1 header */
}

.about-content .profile-picture {
  width: 50%; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Ensure image is block level */
  margin: 10px auto; /* Center image horizontally */
}

.about-content .chuck-photo {
  width: 50%; /* Adjust width as needed */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Ensure image is block level */
  margin: 10px auto; /* Center image horizontally */

  }

.about-content p {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 22px;
  /* Text will wrap and not expand indefinitely */
}

/* Styles for wider screens */
@media (min-width: 768px) {

  .about-content {
    display: flex; /* Enable flexbox */
    flex-direction: column; /* Stack items vertically */
    align-items: flex-start; /* Align items to the start (left) */
    margin-left: 10px;

  }

  .about-content h1 {
    font-size: 44px; /* Larger font size for wider screens */
    margin-top: 20px;
    margin-bottom: 0px;

  }

  .about-content h2 {
    font-size: 36px; /* Larger font size for wider screens */
    margin-bottom: 0px;
    margin-top: 10px;

  }

  .about-content .profile-picture {
    width: 25%; /* Adjust image width for wider screens */
    margin: 10px 0; /* Adjust spacing around the image */

  }

    .about-content .chuck-photo {
    width: 25%; /* Adjust image width for wider screens */
    margin: 10px 0; /* Adjust spacing around the image */

  }

  .about-content p {
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 800px; /* Limit the width of paragraphs for readability */

    font-size: 22px

  }
}
