/* Header.css */

.App-header {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    display: flex; /* Enables flexbox */
    justify-content: left; /* Centers the navigation menu horizontally */
    flex-direction: row;
    align-items: center; /* Aligns items vertically */
}

.App-header img {
  height: 160px; /* Adjust the height as needed */
  width: auto; /* This will maintain the aspect ratio of the logo */
}

.side-panel a {
  color: black; /* Set the text color */
  padding: 5px 10px; /* Add some padding */
  text-decoration: none; /* Remove underline */
  font-size: 26px; /* Set font size */
  font-weight: bold;
  /* Add more styles as needed */
}

.side-panel a:hover, .navbar a:hover {
  text-decoration: underline; /* Add underline on hover */
}

.hamburger {
  display: none; /* Hide hamburger menu on larger screens */
  font-size: 24px;
  cursor: pointer;
}

  .side-panel .close-btn {
  display:none;
}

/* Responsive styles */
@media screen and (max-width: 1020px) {
  .navbar{
    display: flex;
    justify-content: flex-end; /* Align items to the right */
    width: 100%; /* Full width */
  }

  .hamburger {
    display: block; /* Show hamburger icon on smaller screens */
    font-size: 100px;
    margin-right: 20px;
    color: black;
    background-color: white;
    border: none;

  }

  .side-panel {
    position: fixed;
    top: 0;
    right: -100%; /* Start off-screen to the right */
    width: 250px; /* Adjust width as needed */
    height: 100%;
    background-color: white;
    color: black;
    overflow-x: hidden;
    transition: 0.5s; /* Smooth slide-in transition */
    padding-top: 60px; /* Adjust as needed */
    z-index: 100;
  }

  .side-panel a {
    padding: 10px 15px;
    text-decoration: none;
    font-size: 22px;
    color: black;
    display: block;
    transition: 0.3s;
  }

  .side-panel a:hover {
    color: black;
  }

  /* When side panel is open */
  .side-panel.open {
    right: 0; /* Slide in */
  }

  .side-panel .close-btn {
  display:block;
  position: absolute; /* Position the button relative to the side-panel */
  top: 10px; /* Adjust the position as needed */
  right: 10px; /* Adjust the position as needed */
  border: none; /* Remove border */
  background: none; /* Transparent background */
  font-size: 24px; /* Adjust the font size as needed */
  cursor: pointer;
}

  /* Adjust hamburger button as needed */
  .hamburger {
    cursor: pointer;
    /* Other styles... */
  }
}
